import React, { useContext } from "react";
import "./Dose.css";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import { motion } from "framer-motion";
const Dose = ({ content }) => {
  const { t } = useTranslation();
  return (
    <div className="Dose">
      <Container>
        <div className="doseContain">
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h2>{content?.title}</h2>
            <p>{content?.description}</p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="linkMotion"
          >
            <Link to={"/our-works"}>{t("SeeWor")}</Link>
          </motion.div>
        </div>
      </Container>
    </div>
  );
};

export default Dose;
