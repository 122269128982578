import React, { useContext } from "react";
import "./Footer.css";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../../context/LanguageContext";
const Footer = () => {
  const { t } = useTranslation();
  const { lang } = useContext(LanguageContext);
  return (
    <footer>
      <Container>
        <div className="footer">
          <div className="InfoFooter">
            <h4>{t("LetsWork")}</h4>
            <p>
              {t("footerDes")} <br /> {t("footerDess")}
            </p>
            <a href="mailto:Woven@Wovenbranding.com">Woven@Wovenbranding.com</a>
            <p className="phone">
              <span>phone</span>
              <span>+2 01225602122</span>
            </p>
          </div>
          <div className={`LogoFooter ${lang === "ar" ? "logoFooterAr" : ""}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="452"
              height="443"
              viewBox="0 0 582 573"
              fill="none"
            >
              <path
                d="M92.4812 475.328L1.76131 51.756C-2.87197 30.0813 12.1111 10.1027 35.2183 7.12548L83.6952 0.839016C106.802 -2.13824 129.257 12.9728 133.932 34.6623L179.036 245.341C184.096 268.946 215.95 278.369 232.547 261.169L289.855 201.726C315.344 175.313 363.92 186.707 376.459 222.089L421.085 348.222C428.148 368.186 454.663 375.992 470.991 362.928L476.024 358.891C493.321 345.073 520.288 347.583 536.306 364.535L569.89 400.094C585.866 417.031 584.815 441.958 567.504 455.814L435.282 561.538C406.758 584.324 360.553 570.733 348.279 535.911L317.963 450.238C308.372 423.148 271.156 414.385 251.604 434.631L185.772 502.911C156.866 532.881 101.332 516.455 92.5225 475.343L92.4812 475.328Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div className="secFooter">
          <ul>
            {/* <li>
              <a href="#">{t("careers")}</a>
            </li> */}
            <li>
              <a
                href="https://www.instagram.com/woven.adv?igsh=MTBmN2VwZjBpNWo5cg=="
                target="blank"
              >
                {t("instagram")}
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/Wovenadv.eg?mibextid=kFxxJD"
                target="blank"
              >
                {t("facebook")}
              </a>
            </li>
            <li>
              <a href="https://www.tiktok.com/@woven.adv?_t=8qKGQyZWAuV&_r=1" target="blank">
                {t("tiktok")}
              </a>
            </li>
          </ul>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
