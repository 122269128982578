import React, { useContext, useEffect } from "react";
import "./Studio.css";
// import StudioSlider from "../../components/studioComponents/StudioSlider/StudioSlider";
import Dose from "../../components/homeComponents/Dose/Dose";
import { LanguageContext } from "../../context/LanguageContext";
import { useFetchStudioQuery } from "../../redux/slices/StudioSlice";
import ProductsSwiper from "../../components/studioComponents/products/ProductsSwiper";
import LoadingPage from "../LoadingPage";
import StudioSwiper from "../../components/studioComponents/StudioSwiper/StudioSwiper";
import { motion } from "framer-motion";
import MetaTag from "../../utils/MetaTag";
const Studio = () => {
  const meta = {
    en: {
      title: "Studio | Woven Studio | Elevate Your Brand with Creative Design",
      description:
        "Start your journey and elevate your brand with Woven Studio. We craft brilliance and shape perceptions with designs that captivate your audience. Your vision, our design.",
    },
    ar: {
      title:
        "الاستوديو | استوديو ووفن | ارتقِ بعلامتك التجارية بتصاميم إبداعية",
      description:
        "ابدأ رحلتك وارتقِ بعلامتك التجارية مع استوديو ووفن. نحن نصنع الإبداع ونشكل التصورات بتصاميم تلتقط انتباه جمهورك. رؤيتك، تصميمنا.",
    },
  };
  useEffect(() => {
    // تأكد أن التمرير مبدئيًا فوق
    window.scrollTo(0, 0);

    return () => {
      // تنظيف أو إعادة التمرير لو محتاج عند الخروج
      window.scrollTo(0, 0);
    };
  }, []);
  const { lang } = useContext(LanguageContext);
  const { data: studio, error, isLoading } = useFetchStudioQuery(lang);

  if (isLoading) {
    return (
      <div>
        <LoadingPage />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  return (
    <motion.div
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 2 }}
    >
      <MetaTag title={meta[lang].title} description={meta[lang].description} />

      {/* <StudioSlider slides={studio?.data?.banners_one} /> */}
      <StudioSwiper slides={studio?.data?.banners_one} />
      <Dose content={studio?.data?.section} />
      <ProductsSwiper products={studio?.data?.banners_two} />
    </motion.div>
  );
};

export default Studio;
