import React, { useContext } from "react";
import { motion } from "framer-motion";
import { LanguageContext } from "../../../context/LanguageContext";
const Client = ({ client }) => {
  const { lang } = useContext(LanguageContext);
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0 }}
      whileInView={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.6 }}
      className="ClientCard"
    >
      <div className="clientImg">
        <img src={client?.image} alt="client" />
      </div>
      <div className={`clientInfo ${lang === "ar" ? "clientInfo-ar" : ""}`}>
        <h4>{client?.title}</h4>
        <span>{client?.description}</span>
      </div>
    </motion.div>
  );
};

export default Client;
