import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const progressApi = createApi({
  reducerPath: "progressApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    fetchProgress: builder.query({
      query: (lang) => ({
        url: "work/progress",
        headers: {
          lang: lang,
        },
      }),
    }),
  }),
});

export const { useFetchProgressQuery } = progressApi;
