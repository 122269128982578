import React from "react";
import "./CategoryCard.css";
import CategoryCard from "./CategoryCard";
import { Row } from "react-bootstrap";

const CategoryCardList = ({ works }) => {
  return (
    <Row>
      {works?.length <= 0 && <h2 className="text-center" style={{color:"#fff"}}>no data found !</h2>}
      {works?.map((item) => (
        <CategoryCard key={item?.id} item={item} />
      ))}
      {/* <CategoryCard item={categoryCard2} />
      <CategoryCard item={categoryCard2} />
      <CategoryCard item={categoryCard} />
      <CategoryCard item={categoryCard} />
      <CategoryCard item={categoryCard2} /> */}
    </Row>
  );
};

export default CategoryCardList;
