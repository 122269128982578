import React, { useContext, useEffect } from "react";
import "./WorkDetails.css";
import HeroDetails from "../../components/DetailsComponents/HeroDetails/HeroDetails";
import DetailsDescription from "../../components/DetailsComponents/DetailsDescription/DetailsDescription";
import WorkList from "../../components/DetailsComponents/WorkList/WorkList";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchWorkDetails } from "../../redux/slices/WorkDetailsSlice";
import { LanguageContext } from "../../context/LanguageContext";
import LoadingPage from "../LoadingPage";
import { motion } from "framer-motion";
import MetaTag from "../../utils/MetaTag";
const WorkDetails = () => {
  useEffect(() => {
    // تأكد أن التمرير مبدئيًا فوق
    window.scrollTo(0, 0);

    return () => {
      // تنظيف أو إعادة التمرير لو محتاج عند الخروج
      window.scrollTo(0, 0);
    };
  }, []);
  const { id } = useParams();
  const { lang } = useContext(LanguageContext);
  const dispatch = useDispatch();
  const { data, isLoading, error } = useSelector((state) => state.workDetails);
  useEffect(() => {
    if (id) {
      dispatch(fetchWorkDetails({ id, lang }));
    }
  }, [id, lang, dispatch]);

  if (isLoading) {
    return (
      <div>
        <LoadingPage />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 2 }}
      className="WorkDetails"
    >
      <MetaTag
        title={
          lang === "ar"
            ? `اعمالنا | ${data?.data?.works?.name ?? ""}`
            : `Our Work | ${data?.data?.works?.name ?? ""}`
        }
        description={data?.data?.works?.description}
      />
      <HeroDetails bg={data?.data?.works?.main_image} />
      <DetailsDescription
        description={data?.data?.works?.description}
        delpack={data?.data?.works?.delpack}
        name={data?.data?.works?.name}
      />
      <WorkList list={data?.data?.works?.images} />
    </motion.div>
  );
};

export default WorkDetails;
