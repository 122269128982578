import React, { useContext, useEffect } from "react";
import HeroAbout from "../../components/AboutComponents/HeroAbout/HeroAbout";
import AboutSection from "../../components/AboutComponents/aboutSection/AboutSection";
import Environment from "../../components/AboutComponents/environmentSection/Environment";
import SwiperEnv from "../../components/AboutComponents/environmentSection/SwiperEnv/SwiperEnv";
import Clients from "../../components/AboutComponents/Clients/Clients";
import { LanguageContext } from "../../context/LanguageContext";
import { useFetchAboutQuery } from "../../redux/slices/AboutSlice";
import LoadingPage from "../LoadingPage";
import MetaTag from "../../utils/MetaTag";
const AboutUs = () => {
  const meta = {
    en: {
      title: "About Us | Woven Studio | Creative Graphic Design & Advertising",
      description:
        "Woven Studio is where creativity meets design. We are passionate storytellers transforming visions into captivating designs. Our team thrives in a collaborative, innovative environment to deliver exceptional results in app design, branding, web design, and more.",
    },
    ar: {
      title: "من نحن | استوديو ووفن | التصميم الجرافيكي والإعلانات الإبداعية",
      description:
        "استوديو ووفن هو المكان الذي تلتقي فيه الإبداع مع التصميم. نحن رواة قصص شغوفون نحول الرؤى إلى تصاميم جذابة. فريقنا يعمل في بيئة تعاونية مليئة بالابتكار لتقديم نتائج استثنائية في تصميم التطبيقات، العلامات التجارية، تصميم المواقع والمزيد.",
    },
  };
  const { lang } = useContext(LanguageContext);
  const { data: aboutData, isLoading } = useFetchAboutQuery(lang);
  useEffect(() => {
    // تأكد أن التمرير مبدئيًا فوق
    window.scrollTo(0, 0);

    return () => {
      // تنظيف أو إعادة التمرير لو محتاج عند الخروج
      window.scrollTo(0, 0);
    };
  }, []);
  if (isLoading) {
    return <LoadingPage />;
  }
  return (
    <div>
      <MetaTag title={meta[lang].title} description={meta[lang].description} />

      <HeroAbout data={aboutData?.data?.about} />
      <AboutSection
        content={aboutData?.data?.section_one}
        tools={aboutData?.data?.tools}
      />
      <Environment content={aboutData?.data?.section_two} />
      <SwiperEnv environmentImgs={aboutData?.data?.sliders_abouts} />
      <Clients employees={aboutData?.data?.employees} />
    </div>
  );
};

export default AboutUs;
