import React, { useContext } from "react";
import "./Environment.css";
import { Container } from "react-bootstrap";
import { motion } from "framer-motion";
import { LanguageContext } from "../../../context/LanguageContext";
const Environment = ({ content }) => {
  const { lang } = useContext(LanguageContext);
  return (
    <motion.div
      initial={{ opacity: 0, x: lang === "ar" ? 100 : -100 }}
      exit={{ opacity: 0, x: lang === "ar" ? -100 : 100 }}
      style={{ overflow: "hidden" }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.9 }}
      className="Environment"
    >
      <Container>
        <h2>{content?.title}</h2>
        <p>{content?.description}</p>
      </Container>
    </motion.div>
  );
};

export default Environment;
