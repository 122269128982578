import React, { useContext, useEffect, useState } from "react";
import "./CategoryList.css";
import { Container } from "react-bootstrap";
import { LanguageContext } from "../../../context/LanguageContext";
import { useFetchWorksQuery } from "../../../redux/slices/WorksSlice";
import { useTranslation } from "react-i18next";

const CategoryList = ({
  categories,
  categoriesSelected,
  setCategoriesSelected,
}) => {
  const { lang } = useContext(LanguageContext);
  const [showCategories, setShowCategories] = useState(false);

  const toggleCategories = () => {
    setShowCategories(!showCategories);
  };

  const handleCategoryClick = (id) => {
    setCategoriesSelected((prevSelected) => {
      if (prevSelected.includes(id)) {
        // إذا كان العنصر موجودًا بالفعل في state، قم بإزالته
        return prevSelected.filter((categoryId) => categoryId !== id);
      } else {
        // إذا لم يكن موجودًا، قم بإضافته
        return [...prevSelected, id];
      }
    });
  };
  const { t } = useTranslation();
  return (
    <div className={`category-container ${lang === "ar" ? "category-container-ar" : ''}`}>
      <Container>
        <button onClick={toggleCategories}>{t("categories")}</button>
        <ul
          className={
            showCategories && lang === "en"
              ? "show"
              : showCategories && lang === "ar"
              ? "show showar"
              : ""
          }
        >
          {categories?.map((item) => (
            <li
              key={item?.id}
              onClick={() => handleCategoryClick(item?.id)}
              style={{
                color: categoriesSelected?.find((id) => item?.id === id)
                  ? "#2db7e4"
                  : "#fff",
              }}
            >
              {item?.name}
            </li>
          ))}
        </ul>
      </Container>
    </div>
  );
};

export default CategoryList;
