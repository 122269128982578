import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Scrollbar, A11y } from "swiper/modules";
import "./SwiperEnv.css";
const SwiperEnv = ({ environmentImgs }) => {
  const swiperRef = useRef(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current) {
      const swiperInstance = swiperRef.current.swiper;
      swiperInstance.params.navigation.prevEl = prevRef.current;
      swiperInstance.params.navigation.nextEl = nextRef.current;
      swiperInstance.navigation.init();
      swiperInstance.navigation.update();
    }
  }, []);
  return (
    <div className="SwiperEnv">
      <Swiper
        slidesPerView={1}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        loop
        scrollbar={{ draggable: true }}
        modules={[Navigation, Scrollbar, A11y]}
        className="customSwipe"
      >
        {environmentImgs?.map((imge) => (
          <SwiperSlide key={imge?.id}>
            <img src={imge?.image_path} alt="Slide 1" />
          </SwiperSlide>
        ))}
        <div
          ref={prevRef}
          className="env-swiper-button-prev"
          style={{
            position: "absolute",
            top: "50%",
            left: "10%",
            zIndex: 10,
            cursor: "pointer",
            transform: "translateY(-50%)",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="49"
            height="100"
            viewBox="0 0 69 151"
            fill="none"
          >
            <rect opacity="0.55" width="69" height="151" fill="#D9D9D9" />
            <path d="M9 75L48 52.4833L48 97.5167L9 75Z" fill="#D9D9D9" />
          </svg>
        </div>

        <div
          ref={nextRef}
          className="env-swiper-button-next"
          style={{
            position: "absolute",
            top: "50%",
            right: "10%",
            zIndex: 10,
            cursor: "pointer",
            transform: "translateY(-50%)",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="49"
            height="100"
            viewBox="0 0 69 151"
            fill="none"
          >
            <rect opacity="0.55" width="69" height="151" fill="#D9D9D9" />
            <path d="M60 75L21 97.5167L21 52.4833L60 75Z" fill="#D9D9D9" />
          </svg>
        </div>
      </Swiper>
    </div>
  );
};

export default SwiperEnv;
