import React, { useEffect, useRef } from "react";
import Client from "./Client";
import "./Clients.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Scrollbar, A11y } from "swiper/modules";
const Clients = ({ employees }) => {
  const swiperRef = useRef(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current) {
      const swiperInstance = swiperRef.current.swiper;
      swiperInstance.params.navigation.prevEl = prevRef.current;
      swiperInstance.params.navigation.nextEl = nextRef.current;
      swiperInstance.navigation.init();
      swiperInstance.navigation.update();
    }
  }, []);
  return (
    <div className={`aboutClients`}>
      <Swiper
        ref={swiperRef}s
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        loop
        scrollbar={{ draggable: true }}
        modules={[Navigation, Scrollbar, A11y]}
        className="customSwipe"
        breakpoints={{
          320: {
            slidesPerView: 1, // عدد اللوجوهات للشاشات الصغيرة جداً (موبايل)
            spaceBetween: 0,
          },
          480: {
            slidesPerView: 2, // للشاشات الموبايل المتوسطة
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3, // للشاشات التابلت
            spaceBetween: 10,
          },
          994: {
            slidesPerView: 3, // للشاشات التابلت
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 4, // للشاشات الكبيرة مثل اللابتوب والديسكتوب
            spaceBetween: 30,
          },
        }}
      >
        {employees?.map((item) => (
          <SwiperSlide key={item?.id}>
            <Client client={item} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div
        ref={prevRef}
        className="cl-swiper-button-prev"
        style={{
          position: "absolute",
          top: "45%",
          left: "4%",
          zIndex: 10,
          cursor: "pointer",
          transform: "translateY(-50%)",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="120"
          height="63"
          viewBox="0 0 147 73"
          fill="none"
        >
          <path
            d="M117.416 33.0612C100.016 30.6338 82.5156 29.5213 65.0156 27.5996C62.0156 27.2962 55.8156 28.611 55.4156 25.4757C55.0156 22.3404 61.3156 21.1267 63.9156 19.4073C68.1156 16.4743 73.1156 14.2492 76.4156 10.5071C80.6156 5.75349 78.2156 1.30335 71.9156 0.999929C68.8156 0.898789 65.4156 1.20221 62.5156 2.31474C45.0156 9.0911 27.6156 15.9686 10.3156 23.1495C5.11562 25.2734 0.315613 27.8019 0.015625 34.9828C-0.284378 41.8603 3.61562 45.2991 8.81561 48.131C22.9156 55.7164 36.9156 63.5042 51.2156 70.4828C54.1156 71.8988 60.2156 73.3147 62.1156 70.1794C64.0156 67.0441 59.9156 62.2905 57.4156 60.1666C51.9156 55.6153 45.4156 52.2777 39.4156 48.3333C38.2156 47.5241 35.9156 46.5127 36.3156 44.9957C36.7156 43.2763 39.6156 43.2763 41.2156 43.3774C63.1156 44.3888 85.1156 45.8048 107.016 46.8162C118.816 47.3219 147.216 51.4686 146.916 42.5683C146.616 37.1068 127.416 34.4771 117.416 33.0612Z"
            fill="black"
          />
        </svg>
      </div>

      <div
        ref={nextRef}
        className="cl-swiper-button-next"
        style={{
          position: "absolute",
          top: "45%",
          right: "5%",
          zIndex: 10,
          cursor: "pointer",
          transform: "translateY(-50%)",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="120"
          height="63"
          viewBox="0 0 147 73"
          fill="none"
        >
          <path
            d="M29.5024 33.0612C46.9024 30.6338 64.4024 29.5213 81.9024 27.5996C84.9024 27.2962 91.1023 28.611 91.5023 25.4757C91.9024 22.3404 85.6024 21.1267 83.0024 19.4073C78.8024 16.4743 73.8024 14.2492 70.5024 10.5071C66.3024 5.75349 68.7024 1.30335 75.0024 0.999929C78.1024 0.898789 81.5023 1.20221 84.4024 2.31474C101.902 9.0911 119.302 15.9686 136.602 23.1495C141.802 25.2734 146.602 27.8019 146.902 34.9828C147.202 41.8603 143.302 45.2991 138.102 48.131C124.002 55.7164 110.002 63.5042 95.7024 70.4828C92.8024 71.8988 86.7024 73.3147 84.8024 70.1794C82.9024 67.0441 87.0023 62.2905 89.5023 60.1666C95.0024 55.6153 101.502 52.2777 107.502 48.3333C108.702 47.5241 111.002 46.5127 110.602 44.9957C110.202 43.2763 107.302 43.2763 105.702 43.3774C83.8024 44.3888 61.8024 45.8048 39.9024 46.8162C28.1024 47.3219 -0.297642 51.4686 0.00235842 42.5683C0.302358 37.1068 19.5024 34.4771 29.5024 33.0612Z"
            fill="black"
          />
        </svg>
      </div>
    </div>
  );
};

export default Clients;
