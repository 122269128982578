import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/free-mode";
import { FreeMode } from "swiper/modules"; // استيراد الـ Autoplay
import { useTranslation } from "react-i18next";
import "./ProductsSwiper.css";
const ProductsSwiper = ({ products }) => {
  // const logos = [c1, c2, c3, c4, c5, c6, c7, c8];
  const { t } = useTranslation();
  return (
    <div className="ProductsSwiper">
      <Swiper
        spaceBetween={0}
        slidesPerView={4} // عدد اللوجوهات اللي تظهر في وقت واحد
        loop={false} // يعيد اللوجوهات من الأول بعد ما يخلص
        freeMode={true} // يخلي الحركة حرة ومستمرة
        modules={[FreeMode]}
        breakpoints={{
          320: {
            slidesPerView: 2, // عدد اللوجوهات للشاشات الصغيرة جداً (موبايل)
            spaceBetween: 0,
          },
          480: {
            slidesPerView: 2, // للشاشات الموبايل المتوسطة
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 3, // للشاشات التابلت
            spaceBetween: 0,
          },
          1024: {
            slidesPerView: 4, // للشاشات الكبيرة مثل اللابتوب والديسكتوب
            spaceBetween: 0,
          },
        }}
      >
        {products?.map((product) => (
          <SwiperSlide key={product?.id}>
            <img src={product?.image_path} alt={`client-logo-${product?.id}`} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ProductsSwiper;
