import React from "react";
import { Container } from "react-bootstrap";
import ToolsSection from "./ToolsSection";
import "./AboutSection.css";
import { motion } from "framer-motion";
const AboutSection = ({ content, tools }) => {
  return (
    <motion.div
      // initial={{ opacity: 0, y: 100 }}
      // whileInView={{ opacity: 1, y: 0 }}
      // transition={{ duration: 0.6 }}
      className="AboutSection"
    >
      <Container>
        <h2>{content?.title}</h2>
        <p>{content?.description}</p>
        <ToolsSection tools={tools} />
      </Container>
    </motion.div>
  );
};

export default AboutSection;
