import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/free-mode";

import { Autoplay, FreeMode } from "swiper/modules"; // استيراد الـ Autoplay
import { useTranslation } from "react-i18next";
const SwiperClients = ({ clients }) => {
  // const logos = [c1, c2, c3, c4, c5, c6, c7, c8];
  const { t } = useTranslation();
  return (
    <div style={{ width: "100%", direction: "ltr" }}>
      <h3 className="text-center clientHead">{t("our_clients")}</h3>
      <Swiper
        spaceBetween={30}
        slidesPerView={7} // عدد اللوجوهات اللي تظهر في وقت واحد
        autoplay={{
          delay: 0, // عشان يتحرك من غير توقف
          disableOnInteraction: false, // يكمل الحركة حتى لو حصل تفاعل
          pauseOnMouseEnter: false, // يستمر في الحركة حتى لو الماوس دخل على اللوجوهات
        }}
        speed={3000} // سرعة الحركة - أسرع يعني يتحرك بسرعة أكبر
        loop={true} // يعيد اللوجوهات من الأول بعد ما يخلص
        freeMode={true} // يخلي الحركة حرة ومستمرة
        modules={[Autoplay, FreeMode]}
        breakpoints={{
          320: {
            slidesPerView: 4, // عدد اللوجوهات للشاشات الصغيرة جداً (موبايل)
            spaceBetween: 20,
          },
          480: {
            slidesPerView: 4, // للشاشات الموبايل المتوسطة
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 5, // للشاشات التابلت
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 7, // للشاشات الكبيرة مثل اللابتوب والديسكتوب
            spaceBetween: 30,
          },
        }}
        // direction={'ltr'}
      >
        {clients?.map((logo) => (
          <SwiperSlide key={logo?.id}>
            <img
              src={logo?.image_path}
              alt={`client-logo-${logo?.id}`}
              style={{ width: "100%", height: "100px", objectFit: "contain" }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SwiperClients;
