import React from "react";
import "./Content.css";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
const ContentContact = () => {
  const { t } = useTranslation();
  return (
    <div className="ContentContact">
      <Container>
        <h1>{t("get_in_touch")}</h1>
        <p>{t("ready_to_share")}</p>
      </Container>
    </div>
  );
};

export default ContentContact;
