import React from "react";
import { Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {motion} from 'framer-motion'
const CategoryCard = ({ item }) => {
  return (
    <Col lg={6} md={6} sm={6} xs={6} className="p-0">
      <motion.div
        initial={{ opacity: 0, scale: 0 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.6 }}
      >
        <NavLink to={`/work/${item?.id}`}>
          <div className="categoryCard">
            <img src={item?.main_image} alt={`category-image-${item.id}`} />
          </div>
        </NavLink>
      </motion.div>
    </Col>
  );
};

export default CategoryCard;
