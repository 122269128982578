import React from "react";
import "./WorkList.css";
import { Container, Row, Col } from "react-bootstrap";
import { motion } from "framer-motion";
const WorkList = ({ list }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      className="WorkList"
    >
      <Container>
        <Row>
          {list?.map((item) => (
            <Col lg="12" key={item?.id}>
              <img src={item?.image_path} alt="details1" />
            </Col>
          ))}
          {/* <Col lg="12">
            <img src={details2} alt="details2" />
          </Col>
          <Col lg="12">
            <img src={details3} alt="details3" />
          </Col> */}
        </Row>
      </Container>
    </motion.div>
  );
};

export default WorkList;
