import React from "react";
import "./HeroDetails.css";
const HeroDetails = ({ bg }) => {
  return (
    <div className="HeroDetails">
      <img src={bg} alt="work-panner" />
    </div>
  );
};

export default HeroDetails;
