import React, { useContext, useEffect } from "react";
import "./Contact.css";
import ContentContact from "../../components/ContactUsComponents/content/ContentContact";
import ContactForm from "../../components/ContactUsComponents/contactForm/ContactForm";
import ContactLocation from "../../components/ContactUsComponents/ContactLocation/ContactLocation";
import { motion } from "framer-motion";
import MetaTag from "../../utils/MetaTag";
import { LanguageContext } from "../../context/LanguageContext";

const Contact = () => {
  const { lang } = useContext(LanguageContext);
  const meta = {
    en: {
      title: "Contact Us | Woven Studio | Let's Make Things Happen",
      description:
        "Get in touch with Woven Studio and share your thoughts! Whether you want to fill out our contact form or send us an email, we are here to collaborate and create something amazing together. Visit us to explore our creative environment.",
    },
    ar: {
      title: "تواصل معنا | استوديو ووفن | دعنا نجعل الأمور تحدث",
      description:
        "تواصل مع استوديو ووفن وشارك أفكارك! سواء كنت ترغب في ملء نموذج الاتصال أو إرسال رسالة عبر البريد الإلكتروني، نحن هنا للتعاون معك لصنع شيء رائع معًا. زورونا لاستكشاف بيئتنا الإبداعية.",
    },
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 2 }}
      className="Contact"
    >
      <MetaTag title={meta[lang].title} description={meta[lang].description} />

      <ContentContact />
      <ContactForm />
      <ContactLocation />
    </motion.div>
  );
};

export default Contact;
