import React, { useContext, useEffect, useState } from "react";
import "./Work.css";
import HeroWorks from "../../components/workComponents/heroWorks/HeroWorks";
import CategoryList from "../../components/workComponents/CategoryList/CategoryList";
import CategoryCardList from "../../components/workComponents/Cards/CategoryCardList";
import { LanguageContext } from "../../context/LanguageContext";
import { useFetchWorksQuery } from "../../redux/slices/WorksSlice";
import LoadingPage from "../LoadingPage";
import { motion } from "framer-motion";
import MetaTag from "../../utils/MetaTag";
const Work = () => {
  useEffect(() => {
    // تأكد أن التمرير مبدئيًا فوق
    window.scrollTo(0, 0);

    return () => {
      // تنظيف أو إعادة التمرير لو محتاج عند الخروج
      window.scrollTo(0, 0);
    };
  }, []);
  const { lang } = useContext(LanguageContext);
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const meta = {
    en: {
      title: "Our Work | Woven Studio | Crafting Brands Creating Impact",
      description:
        "From simple visions to big ideas, Woven Studio creates success stories. We excel at uncovering insights and crafting creative strategies that distinguish businesses from their competitors across all channels.",
    },
    ar: {
      title: "أعمالنا | استوديو ووفن | ابتكار علامات تجارية تترك أثرًا",
      description:
        "من رؤية بسيطة إلى أفكار كبيرة، ووفن استوديو يصنع قصص نجاح. نحن نتميز في اكتشاف الأفكار وصياغة استراتيجيات إبداعية تميز الشركات عن منافسيها عبر جميع القنوات.",
    },
  };
  const {
    data: works,
    error,
    isLoading,
  } = useFetchWorksQuery({
    lang,
    category_ids: categoriesSelected,
  });

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 2 }}
      className="workPage"
    >
      <MetaTag title={meta[lang].title} description={meta[lang].description} />

      <HeroWorks worksTitle={works?.data?.section} />
      <CategoryList
        categories={works?.data?.categories}
        categoriesSelected={categoriesSelected}
        setCategoriesSelected={setCategoriesSelected}
      />
      <CategoryCardList works={works?.data?.works} />
      <div className="sectionFooter"></div>
    </motion.div>
  );
};

export default Work;
