import React, { useContext, useState } from "react";
import AboutModal from "./AboutModal";
import { motion } from "framer-motion";
import { LanguageContext } from "../../../context/LanguageContext";
const ToolsSection = ({ tools }) => {
  const { lang } = useContext(LanguageContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataModal, setDataModal] = useState();
  const handleCardClick = (id) => {
    const findTools = tools?.find((item) => item?.id === id);
    setDataModal(findTools);
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "auto";
  };
  const getInitialX = (lang, index) => {
    if (index === 0 && lang === "ar") {
      return 100;
    } else if (index === 0 && lang === "en") {
      return -100;
    } else if (index !== 0 && lang === "ar") {
      return -100;
    } else if (index !== 0 && lang === "en") {
      return 100;
    } else {
      return -100;
    }
  };
  return (
    <div className="ToolsSection">
      {tools?.map((item, index) => (
        <motion.div
          initial={{
            opacity: 0,
            x: getInitialX(lang, index),
          }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6 }}
          className="cardTools"
          onClick={() => handleCardClick(item?.id)}
          key={item?.id}
        >
          <img src={item?.image_path} alt="tools" />
          <div
            className={`cardTools-content ${
              lang === "ar" ? "cardTools-content-ar" : ""
            }`}
          >
            <h3>{item?.title}</h3>
            <ul>
              {item?.list?.map((item) => (
                <li key={item?.id}>{item?.title}</li>
              ))}
            </ul>
          </div>
        </motion.div>
      ))}
      {isModalOpen && (
        <AboutModal onClose={handleCloseModal} toolsData={dataModal} />
      )}
    </div>
  );
};

export default ToolsSection;
