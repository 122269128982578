import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const homeSectionApi = createApi({
  reducerPath: "homeSectionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    fetchHome: builder.query({
      query: (lang) => ({
        url: "home/section",
        headers: {
          lang: lang,
        },
      }),
    }),
  }),
});

export const { useFetchHomeQuery } = homeSectionApi;
