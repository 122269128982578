import { configureStore } from "@reduxjs/toolkit";
import { bannersApi } from "./slices/BannerSlice";
import { homeSectionApi } from "./slices/HomeSection";
import { ClientsApi } from "./slices/ClientsSlice";
import { progressApi } from "./slices/progress";
import { WorksApi } from "./slices/WorksSlice";
import WorkDetailsSlice from "./slices/WorkDetailsSlice";
import { aboutApi } from "./slices/AboutSlice";
import { studioApi } from "./slices/StudioSlice";

const store = configureStore({
  reducer: {
    workDetails: WorkDetailsSlice,

    [bannersApi.reducerPath]: bannersApi.reducer,
    [homeSectionApi.reducerPath]: homeSectionApi.reducer,
    [ClientsApi.reducerPath]: ClientsApi.reducer,
    [progressApi.reducerPath]: progressApi.reducer,
    [WorksApi.reducerPath]: WorksApi.reducer,
    [aboutApi.reducerPath]: aboutApi.reducer,
    [studioApi.reducerPath]: studioApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(bannersApi.middleware)
      .concat(homeSectionApi.middleware)
      .concat(ClientsApi.middleware)
      .concat(WorksApi.middleware)
      .concat(progressApi.middleware)
      .concat(aboutApi.middleware)
      .concat(studioApi.middleware),
});

export default store;
