import React, { useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Container } from "react-bootstrap";
import "./ContactForm.css";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../../context/LanguageContext";
import axios from "axios";
import { notify } from "../../../utils/notify";
import { useNavigate } from "react-router-dom";

const ContactForm = () => {
  const { t } = useTranslation();
  const { lang } = useContext(LanguageContext);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("name_required")),
      email: Yup.string()
        .email(t("email_invalid"))
        .required(t("email_required")),
      message: Yup.string().required(t("message_required")),
    }),
    onSubmit: async (values) => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}contacts`,
          values
        );
        if (res.status === 200 && res.data.status === 1) {
          if (lang === "ar") {
            notify("تم إرسال البيانات بنجاح", "success");
            formik.resetForm();
          } else {
            notify("Message sent successfully", "success");
            formik.resetForm();
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
  });

  return (
    <div className="contactForm">
      <Container>
        <form onSubmit={formik.handleSubmit}>
          <div className="inputContainer">
            <label htmlFor="name">{t("name")}</label>
            <input
              id="name"
              name="name"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />

            <div className="error">
              {formik.touched.name && formik.errors.name && formik.errors.name}
            </div>
          </div>

          <div className="inputContainer">
            <label htmlFor="email">{t("email")}</label>
            <input
              id="email"
              name="email"
              type="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            <div className="error">
              {formik.touched.email &&
                formik.errors.email &&
                formik.errors.email}
            </div>
          </div>

          <div className="inputContainer">
            <label htmlFor="message">{t("message")}</label>
            <textarea
              id="message"
              name="message"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.message}
              rows={5}
            />
            <div className="error">
              {formik.touched.message &&
                formik.errors.message &&
                formik.errors.message}
            </div>
          </div>

          <button type="submit">{t("send_my_message")}</button>
        </form>
      </Container>
    </div>
  );
};

export default ContactForm;
