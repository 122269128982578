import React, { useContext } from "react";
import "./HeroWorks.css";
import { Container } from "react-bootstrap";
import { motion } from "framer-motion";
import { LanguageContext } from "../../../context/LanguageContext";
const HeroWorks = ({ worksTitle }) => {
  const { lang } = useContext(LanguageContext);
  return (
    <motion.div
      // initial={{ opacity: 0, x: 100 }}
      initial={{ opacity: 0, x: lang === "ar" ? -100 : 100 }}
      exit={{ opacity: 0, x: lang === "ar" ? 100 : -100 }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.6 }}
      className="HeroWorks"
    >
      <Container>
        <h1>{worksTitle?.title}</h1>
        <p>{worksTitle?.description}</p>
      </Container>
    </motion.div>
  );
};

export default HeroWorks;
