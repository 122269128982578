import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const WorksApi = createApi({
  reducerPath: "WorksApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    fetchWorks: builder.query({
      query: ({ lang, category_ids }) => {
        const params = new URLSearchParams();
        category_ids?.forEach((id) => params.append("category_id[]", id));

        return {
          url: `works?${params.toString()}`, // صياغة الـ URL مع الـ params
          headers: {
            lang: lang,
          },
        };
      },
      staleTime: 1000 * 60 * 5,
    }),
  }),
});

export const { useFetchWorksQuery } = WorksApi;
