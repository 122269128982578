import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Suspense, useContext, useEffect } from "react";
import Home from "./pages/Home/Home";
import NotFound from "./pages/not-found/NotFound";
import MainLayOut from "./components/layout/MainLayout";
import LoadingPage from "./pages/LoadingPage";
import Work from "./pages/Work/Work";
import WorkDetails from "./pages/WorkDetails/WorkDetails";
import { LanguageContext } from "./context/LanguageContext";
import i18n from "./i18n";
import AboutUs from "./pages/about/AboutUs";
import Studio from "./pages/studio/Studio";
import Contact from "./pages/contact/Contact";

function App() {
  const { lang } = useContext(LanguageContext);
  const langLocal = localStorage.getItem("lang");
  useEffect(() => {
    if (langLocal && ["en", "ar"].includes(langLocal)) {
      i18n.changeLanguage(langLocal); // Set the language from local storage
    }
  }, [langLocal]);
  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainLayOut />,
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<LoadingPage />}>
              <Home />
            </Suspense>
          ),
        },
        {
          path: "our-works",
          element: (
            <Suspense fallback={<LoadingPage />}>
              <Work />
            </Suspense>
          ),
        },
        {
          path: "/work/:id",
          element: (
            <Suspense fallback={<LoadingPage />}>
              <WorkDetails />
            </Suspense>
          ),
        },
        {
          path: "/studio",
          element: (
            <Suspense fallback={<LoadingPage />}>
              <Studio />
            </Suspense>
          ),
        },
        {
          path: "/about-us",
          element: (
            <Suspense fallback={<LoadingPage />}>
              <AboutUs />
            </Suspense>
          ),
        },
        {
          path: "/contact-us",
          element: (
            <Suspense fallback={<LoadingPage />}>
              <Contact />
            </Suspense>
          ),
        },
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
  ]);
  return (
    <div
      style={{
        direction: `${lang === "ar" ? "rtl" : "ltr"}`,
        fontFamily: `${
          lang === "ar" ? '"GE SS Unique", sans-serif' : '"Archivo", sans-serif'
        }`,
      }}
    >
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
