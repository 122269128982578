import React, { useContext } from "react";
import "./WorkProgress.css";
import { Container } from "react-bootstrap";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../../context/LanguageContext";
import { useFetchProgressQuery } from "../../../redux/slices/progress";
import { motion } from "framer-motion";
const WorkProgress = () => {
  const [progress, setProgress] = useState(null);
  const { t } = useTranslation();
  const { lang } = useContext(LanguageContext);
  const { data: progressData, error, isLoading } = useFetchProgressQuery(lang);
  return (
    <motion.div
      initial={{ opacity: 0, x: lang === "ar" ? 100 : -100 }}
      exit={{ opacity: 0, x: lang === "ar" ? -100 : 100 }}
      style={{ overflow: "hidden" }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.8 }}
      className="WorkProgress"
    >
      <Container>
        <h3>{t("workProgress")}</h3>
        <ul>
          {progressData?.data?.map((item) => (
            <li key={item?.id}>
              <span
                onMouseEnter={() => {
                  setProgress(item?.image);
                }}
                onMouseLeave={() => {
                  setProgress(null);
                }}
              >
                {item?.title}
              </span>
            </li>
          ))}
        </ul>
      </Container>
      {progress && (
        <div
          className={`imageProgress ${lang == "ar" ? "imageProgressAr" : ""}`}
        >
          <img src={progress} alt="progress-image" />
        </div>
      )}
    </motion.div>
  );
};

export default WorkProgress;
