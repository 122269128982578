import React, { useContext } from "react";
import "./DetailsDescription.css";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { LanguageContext } from "../../../context/LanguageContext";
const DetailsDescription = ({ description, name, delpack }) => {
  const { lang } = useContext(LanguageContext);
  const { t } = useTranslation();
  return (
    <div className="DetailsDescription">
      <Container>
        <div className="containerDescription">
          <motion.div
            initial={{ opacity: 0, x: lang === "ar" ? 100 : -100 }}
            exit={{ opacity: 0, x: lang === "ar" ? -100 : 100 }}
            style={{ overflow: "hidden" }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            className="WorkService"
          >
            <div className="WorkServiceList">
              <h4>{t("delpack")}</h4>
              <ul>
                <li>{name}</li>
              </ul>
            </div>
            <div className="WorkServiceList">
              <h4>{t("delpack")}</h4>
              <ul>
                {delpack?.map((item) => (
                  <li key={item?.id}>{item?.steps}</li>
                ))}
              </ul>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: lang === "ar" ? -100 : 100 }}
            exit={{ opacity: 0, x: lang === "ar" ? 100 : -100 }}
            style={{ overflow: "hidden" }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            className="WorkDescription"
          >
            <p>{description}</p>
          </motion.div>
        </div>
      </Container>
    </div>
  );
};

export default DetailsDescription;
