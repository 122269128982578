import React, { useState } from "react";
import "./AboutModal.css";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
const AboutModal = ({ onClose, toolsData }) => {
  const [activeFaq, setActiveFaq] = useState(null);
  const { t } = useTranslation();
  const faqs = toolsData?.list;

  return (
    <div className="modal-overlay">
      <div className="AboutModal">
        <svg
          onClick={onClose}
          xmlns="http://www.w3.org/2000/svg"
          width="92"
          height="92"
          viewBox="0 0 92 92"
          fill="none"
        >
          <path
            d="M24.5039 22.2411L69.7587 67.496"
            stroke="#474747"
            strokeWidth="5"
            strokeLinecap="round"
          />
          <path
            d="M67.4961 22.2411L22.2413 67.496"
            stroke="#474747"
            strokeWidth="5"
            strokeLinecap="round"
          />
        </svg>
        <Container>
          {/* <h4>tools we make</h4> */}
          <h4>{toolsData?.title}</h4>
          <ul>
            {faqs.map((item, index) => (
              <li key={index}>
                <div
                  className={`Faqtitle ${
                    activeFaq === index ? "faqActive" : ""
                  }`}
                >
                  <span>{item?.title}</span>
                  <svg
                    onClick={() =>
                      setActiveFaq(activeFaq === index ? null : index)
                    }
                    className={`${activeFaq === index ? "rotate" : ""}`}
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                  >
                    <path
                      d="M3 12.5H23"
                      stroke="#474747"
                      strokeWidth="5"
                      strokeLinecap="round"
                    />
                    <path
                      d="M12.5 3L12.5 23"
                      stroke="#474747"
                      strokeWidth="5"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
                {/* <div> */}
                <div
                  className={`Faqdescription ${
                    activeFaq === index ? "showDescription" : "hideDescription"
                  }`}
                  dangerouslySetInnerHTML={{ __html: item?.description }}
                />
                {/* </div> */}
              </li>
            ))}
          </ul>
        </Container>
      </div>
    </div>
  );
};

export default AboutModal;
