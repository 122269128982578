import React, { useContext } from "react";
import "./StudioSwiper.css";
import { LanguageContext } from "../../../context/LanguageContext";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
const StudioSwiper = ({ slides }) => {
  const { lang } = useContext(LanguageContext);
  return (
    <div className={`heroStudio ${lang === "ar" ? "heroStudio-ar" : ""}`}>
      <Swiper
        // autoplay={{ delay: 9000, disableOnInteraction: false }}
        slidesPerView={1}
        loop
        navigation
        scrollbar={{ draggable: true }}
        modules={[Navigation, Scrollbar, Autoplay, A11y]}
      >
        {slides?.map((banner) => {
          const isImage =
            banner?.type === "image" || banner?.type === "" || !banner?.type;
          return (
            <SwiperSlide key={banner?.id}>
              <div
                className={
                  isImage ? "imageHeroStudio" : "imageHero"
                }
              >
                {isImage ? (
                  <img src={banner?.image_path} alt="image swiper" />
                ) : (
                  <video className="custom-video" muted controls>
                    <source src={banner?.image_path} type="video/mp4" />
                  </video>
                )}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default StudioSwiper;
