import React, { useContext } from "react";
import "./Clients.css";
import { Container } from "react-bootstrap";
import SwiperClients from "./SwiperClients";
import { LanguageContext } from "../../../context/LanguageContext";
import { useFetchClientsQuery } from "../../../redux/slices/ClientsSlice";
import { motion } from "framer-motion";
const Clients = () => {
  const { lang } = useContext(LanguageContext);
  const { data: clients, error, isLoading } = useFetchClientsQuery(lang);
  return (
    <div className="clients">
      <Container>
        <motion.div
          initial={{ opacity: 0, x: lang === "ar" ? -100 : 100 }}
          exit={{ opacity: 0, x: lang === "ar" ? 100 : -100 }}
          style={{ overflow: "hidden" }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <p>{clients?.data?.section?.title}</p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, x: lang === "ar" ? 100 : -100 }}
          exit={{ opacity: 0, x: lang === "ar" ? -100 : 100 }}
          style={{ overflow: "hidden" }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
          className="clientsImg"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="48"
            viewBox="0 0 60 48"
            fill="none"
            className="woven"
          >
            <path
              d="M20.3535 45.0036L0.483238 8.53839C-0.532813 6.67213 0.105084 4.31267 1.91182 3.25919L5.70482 1.0536C7.51156 0.00407559 9.79576 0.662989 10.8156 2.52925L20.6973 20.6671C21.805 22.6991 24.6927 22.5137 25.5484 20.3554L28.501 12.8983C29.815 9.58398 34.1313 9.0237 36.1978 11.904L43.5584 22.1744C44.7235 23.8 47.1261 23.6421 48.081 21.8784L48.3751 21.334C49.3874 19.4677 51.6716 18.8009 53.4821 19.8465L57.2789 22.0402C59.0857 23.0858 59.7312 25.4453 58.719 27.3155L50.984 41.5906C49.3148 44.6682 45.1283 44.9444 43.1001 42.1075L38.1 35.1317C36.5187 32.9261 33.2108 33.3522 32.2024 35.8932L28.8104 44.4591C27.3207 48.2192 22.2863 48.5428 20.3573 45.0036H20.3535Z"
              fill="#231F20"
            />
          </svg>
        </motion.div>
        {clients?.data?.clients?.length > 0 && !isLoading && (
          <SwiperClients clients={clients?.data?.clients} />
        )}
      </Container>
    </div>
  );
};

export default Clients;
