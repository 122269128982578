import React, { useContext, useEffect } from "react";
import Hero from "../../components/homeComponents/Hero/Hero";
import Dose from "../../components/homeComponents/Dose/Dose";
import Clients from "../../components/homeComponents/Clients/Clients";
import "./Home.css";
import WorkProgress from "../../components/homeComponents/WorkProgress/WorkProgress";
import { LanguageContext } from "../../context/LanguageContext";
import { useFetchHomeQuery } from "../../redux/slices/HomeSection";
import { useFetchBannersQuery } from "../../redux/slices/BannerSlice";
import LoadingPage from "../LoadingPage";
import { motion } from "framer-motion";
import StudioSwiper from "../../components/studioComponents/StudioSwiper/StudioSwiper";
import MetaTag from "../../utils/MetaTag";
const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);
  const { lang } = useContext(LanguageContext);
  const { data: homeSection, error, isLoading } = useFetchHomeQuery(lang);
  const {
    data: banners,
    error: errorBanners,
    isLoading: loadingBanners,
  } = useFetchBannersQuery(lang);

  const meta = {
    en: {
      title: "Woven Studio | Creative Branding & Advertising Studio in Egypt",
      description:
        "Does your brand make a lasting impact? Woven Studio helps craft unique experiences, products, and spaces that build trust. Co-founded in 2019 in Egypt, we specialize in branding and advertising.",
    },
    ar: {
      title:
        "استوديو ووفن | استوديو إبداعي للعلامات التجارية والإعلانات في مصر",
      description:
        "هل تترك علامتك التجارية أثرًا دائمًا؟ يساعدك ووفن استوديو على ابتكار تجارب ومنتجات ومساحات فريدة تبني الثقة مع عملائك. تأسس في 2019 في مصر، ونحن متخصصون في العلامات التجارية والإعلانات.",
    },
  };

  if (loadingBanners) {
    return (
      <div>
        <LoadingPage />
      </div>
    );
  }

  if (error || errorBanners) {
    return <div>Error: {error}</div>;
  }
  return (
    <motion.div
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 2 }}
    >
      <MetaTag title={meta[lang].title} description={meta[lang].description} />

      <StudioSwiper slides={banners?.data} />
      {/* <Hero banners={banners?.data} /> */}
      <Dose content={homeSection?.data} />
      <Clients />
      <div className="sectionbg"></div>
      <WorkProgress />
    </motion.div>
  );
};

export default Home;
