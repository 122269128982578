import React from "react";
import "./HeroAbout.css";
import { Container } from "react-bootstrap";
import { motion } from "framer-motion";
const HeroAbout = ({ data }) => {
  const style = {
    backgroundImage: `url(${data?.image_path})`,
  };
  return (
    <motion.div
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 2 }}
      className="HeroAbout"
    >
        <div className="aboutHeroContent" style={style}>
          <h1>{data?.title}</h1>
          <p>{data?.description}</p>
        </div>
    </motion.div>
  );
};

export default HeroAbout;
